<template>
    <b-col v-if="inputVal" class="text-right">
        <template v-if="formType=='return'">
            <b-button
                variant="primary"
                class="ml-2"
                @click="$root.$children[0].openModal('document-prepare-modal', {id: offeringId, incoming_invoice_id: null, type: 'gutschrift'},shown)"
            >
                <font-awesome-icon icon="cash-register"/>
                {{ $t('offering.button.gutschrift') }}
            </b-button>
        </template>
        <template v-else>
            <b-dropdown class="mx-1"
                        right
                        variant="warning"
            >
                <template #button-content>
                    <CIcon class="mr-2" name="cil-magnifying-glass"/>
                    <span class="d-none d-lg-inline">{{ $t('offering.button.preview') }}</span>
                </template>
                <b-dropdown-item
                    v-for="(docType,index) in getPreviewDocumentTypes()"
                    :key="index"
                    @click="showPreview(offeringId,docType,true)"
                >
                    <font-awesome-icon class="mr-2" icon="file-invoice" v-if="docType == 'kva'"/>
                    <font-awesome-icon class="mr-2" icon="file-invoice-dollar" v-else-if="docType == 'rechnung'"/>
                    <font-awesome-icon class="mr-2" v-else-if="documentIcons[docType]" :icon="documentIcons[docType]"/>
                    <font-awesome-icon class="mr-2" v-else icon="file"/>
                    {{ $t('offering.button.' + docType) }}
                </b-dropdown-item>
            </b-dropdown>
            <b-dropdown class="mx-1"
                        right
                        variant="primary"
                        :disabled="readonly && !partialReadonly"
            >
                <template #button-content>
                    <font-awesome-icon class="mr-2" icon="file-pdf"/>
                    <span class="d-none d-lg-inline">{{ $t('offering.button.generate') }}</span>
                </template>
                <template v-if="formType=='supply'">
                    <b-dropdown-item
                        v-for="(docType,index) in Object.keys(supplyDocuments)"
                        :key="index"
                        :variant="inputVal[docType].url ? 'success' : 'primary'"
                        v-if="inputVal[docType] && ((docType=='supply_order' && offering.main_supplier) || docType=='supply_request')"
                        @click="getDocument(docType)"
                    >
                        <font-awesome-icon class="mr-2" v-if="inputVal[docType].url" icon="download"/>
                        <font-awesome-icon class="mr-2" v-else :icon="documentIcons[docType]"/>
                        {{ $t('offering.button.' + supplyDocuments[docType]) }}
                    </b-dropdown-item>
                </template>
                <template v-else>
                    <b-dropdown v-if="data.offerings.length"
                                class="p-2 dropdown-child"
                                right
                                variant="success"
                                :text="$t('offering.button.kva')"
                    >
                        <b-dropdown-item key="first"
                                         active
                                         @click="easyDocumentGenerate('kva')"
                        >
                            <font-awesome-icon class="mr-2" icon="file-invoice"/>
                            {{ $t('offering.button.generate') }}
                        </b-dropdown-item>
                        <b-dropdown-item v-for="(document,index) in data.offerings"
                                         :key="index"
                                         :disabled="!document.url"
                                         @click="$root.openDocument(document.name)"
                        >{{ document.name }}
                        </b-dropdown-item>
                    </b-dropdown>
                    <b-dropdown-item
                        v-else
                        variant="primary"
                        @click="easyDocumentGenerate('kva')"
                    >
                        <font-awesome-icon class="mr-2" icon="file-invoice"/>
                        {{ $t('offering.button.kva') }}
                    </b-dropdown-item>

                    <b-dropdown v-if="data.invoices.length"
                                class="p-2 dropdown-child"
                                right
                                variant="success"
                                :text="$t('offering.button.invoice')"
                    >
                        <template
                            v-if="data.invoices.length == 1 && data.percent == 0 && this.getSettings()['allow_order_edit']">
                            <b-dropdown-item key="first"
                                             active
                                             @click="$root.$children[0].openModal('document-prepare-modal', {id: offeringId, type: 'rechnung'},shown('rechnung'))"
                            >
                                <font-awesome-icon class="mr-2" icon="file-invoice"/>
                                {{ $t('offering.button.regenerate') }}
                            </b-dropdown-item>
                        </template>
                        <template v-if="data.percent > 0">
                            <b-dropdown-item key="first"
                                             active
                                             @click="$root.$children[0].openModal('document-prepare-modal', {id: offeringId, type: 'rechnung'},shown('rechnung'))"
                            >
                                <font-awesome-icon class="mr-2" icon="file-invoice"/>
                                {{ $t('offering.button.generate') }}
                            </b-dropdown-item>
                        </template>
                        <template v-else-if="data.has_partial">
                            <b-dropdown-item key="first"
                                             active
                                             @click="$root.$children[0].openModal('document-prepare-modal', {id: offeringId, type: 'rechnung'},shown)"
                            >
                                <font-awesome-icon class="mr-2" icon="file-invoice"/>
                                {{ $t('offering.button.generate') }}
                            </b-dropdown-item>
                        </template>

                        <b-dropdown-item v-for="(document,index) in data.invoices"
                                         :key="index"
                                         :disabled="!document.url"
                                         @click="$root.openDocument(document.name)"
                        >{{ document.name }}
                        </b-dropdown-item>
                    </b-dropdown>
                    <b-dropdown-item v-else
                                     variant="primary"
                                     @click="$root.$children[0].openModal('document-prepare-modal', {id: offeringId, type: 'rechnung'},shown('rechnung'))"
                    >
                        <font-awesome-icon class="mr-2" icon="file-invoice-dollar"/>
                        {{ $t('offering.button.invoice') }}
                    </b-dropdown-item>
                    <b-dropdown v-if="data.reminds.length"
                                class="p-2 dropdown-child"
                                right
                                variant="success"
                                :text="$t('offering.button.remind')"
                    >
                        <b-dropdown-item key="first"
                                         active
                                         @click="easyDocumentGenerate('remind')"
                        >
                            <font-awesome-icon class="mr-2" icon="bell"/>
                            {{ $t('offering.button.generate') }}
                        </b-dropdown-item>
                        <b-dropdown-item v-for="(document,index) in data.reminds"
                                         :key="index"
                                         :disabled="!document.url"
                                         @click="$root.openDocument(document.name)"
                        >{{ document.name }}
                        </b-dropdown-item>
                    </b-dropdown>
                    <b-dropdown-item
                        v-else
                        variant="primary"
                        @click="easyDocumentGenerate('remind')"
                    >
                        <font-awesome-icon class="mr-2" icon="bell"/>
                        {{ $t('offering.button.remind') }}
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-for="(docType,index) in Object.keys(documents)"
                        :key="index"
                        :variant="inputVal[docType].url ? 'success' : 'primary'"
                        v-if="inputVal[docType]"
                        @click="getDocument(docType)"
                    >
                        <font-awesome-icon class="mr-2" v-if="inputVal[docType].url" icon="download"/>
                        <font-awesome-icon class="mr-2" v-else :icon="documentIcons[docType]"/>
                        {{ $t('offering.button.' + documents[docType]) }}
                    </b-dropdown-item>
                    <b-dropdown-item
                        :variant="inputVal['dhl_customs'].url ? 'success' : 'primary'"
                        v-if="inputVal['dhl_customs'] && data.generate_documents.dhl_customs"
                        @click="getDocument('dhl_customs')"
                    >
                        <font-awesome-icon class="mr-2" v-if="inputVal['dhl_customs'].url" icon="download"/>
                        <font-awesome-icon class="mr-2" v-else :icon="documentIcons['dhl_customs']"/>
                        {{ $t('offering.button.dhl_customs') }}
                    </b-dropdown-item>
                    <b-dropdown-item
                        :variant="inputVal['gutschrift'].url ? 'success' : 'primary'"
                        @click="$root.$children[0].openModal('kva-modal', {
                                id: offering.id,
                                formType: 'return'
                            })"
                    >
                        <font-awesome-icon class="mr-2" v-if="inputVal['gutschrift'].url" icon="download"/>
                        <font-awesome-icon class="mr-2" v-else :icon="documentIcons['gutschrift']"/>
                        {{ $t('offering.button.gutschrift') }}
                    </b-dropdown-item>
                    <b-dropdown-item key="mark_cancel"
                                     :disabled="!canCancelInvoice(offering)"
                                     @click="cancelInvoice(offering)"
                    >
                        {{ $t('invoices.button.mark_cancel') }}
                    </b-dropdown-item>
                </template>
            </b-dropdown>
        </template>


        <b-button
            @click="refreshTable()"
            variant="info"
            class="ml-2 mb-3 mt-3"
            :title="this.$t('common.title.actualize')"
        >
            <font-awesome-icon icon="sync"/>
        </b-button>
        <b-dropdown right
                    v-if="!$root.simpleMode"
                    variant="primary"
                    :text="$t('common.label.actions')"
                    class="ml-2 mt-3 mb-3"
        >
            <b-dropdown-item variant="primary"
                             @click="offeringReady"
            >
                <font-awesome-icon class="mr-2" icon="check"/>
                {{ $t('common.title.ready') }}
            </b-dropdown-item>
            <b-dropdown-item variant="primary"
                             @click="reSyncOrder"
                             v-if="data.offering.store && data.offering.store.code!='erika'"
            >
                <font-awesome-icon class="mr-2" icon="sync"/>
                {{ $t('common.button.re_sync_order') }}
            </b-dropdown-item>

            <b-dropdown-item variant="primary"
                             @click="assignStocks"
                             v-if="formType != 'return' && formType!=='supply'"
            >
                <font-awesome-icon class="mr-2" icon="dolly-flatbed"/>
                {{ $t('common.button.assign_stocks') }}
            </b-dropdown-item>
            <b-dropdown-item variant="primary"
                             @click="generateSupplyRequest"
                             v-if="formType == 'supply'"
            >
                <font-awesome-icon class="mr-2" icon="dolly"/>
                {{ $t('common.button.generate_and_send_supply_requests') }}
            </b-dropdown-item>
            <b-dropdown-item variant="primary"
                             @click="$root.$children[0].openModal('supply-stock-push-modal', {id:offering.id}, null)"
                             v-if="formType == 'supply'"
            >
                <font-awesome-icon class="mr-2" icon="dolly-flatbed"/>
                {{ $t('stock.button.supply_stock_push') }}
            </b-dropdown-item>

            <b-dropdown-item variant="primary"
                             @click="deleteOffering"
                             v-if="formType != 'return' && canDeleteOffering()"
            >
                <font-awesome-icon class="mr-2" icon="trash"/>
                {{ $t('common.button.delete_offering') }}
            </b-dropdown-item>

            <b-dropdown-item variant="info"
                             @click="$root.$children[0].openModal('columns-new-modal', {table: formType == 'return' ? 'return_positions' : 'positions'}, refreshTable, {width:'800px'})"
            >
                <font-awesome-icon class="mr-2" icon="cogs"/>
                {{ $t('common.title.config') }}
            </b-dropdown-item>

            <b-dropdown-item variant="info"
                             v-for="(trigger,key) in getTriggers()"
                             :key="'trigger_'+key"
                             @click="trigger.click"
            >
                <font-awesome-icon class="mr-2" :icon="trigger.icon"/>
                {{ trigger.title }}
            </b-dropdown-item>
        </b-dropdown>
    </b-col>
</template>

<script>
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import {mapGetters} from "vuex";

export default {
    name: 'OfferingGeneralActions',
    mixins: [commonSave],
    props: {
        'value': [Object],
        'offeringId': [Number],
        'offering': [Object],
        'formType': [String],
        'shown': [Function],
        'refreshTable': [Function],
        'showPreview': [Function],
        'data': [Object],
        'readonly': [Boolean],
        'partialReadonly': [Boolean],
    },
    data() {
        return {
            loading: true,
            isDropdownChildVisible: false,
            documents: {
                'lieferschein': 'delivery_note',
                'return_note': 'return_note',
                'product_picker': 'product_picker',
                'confirm': 'confirm',
                'shipping_label': 'shipping_label',
                'return': 'return_label',
                'combine': 'combine',
            },
            supplyDocuments: {
                'supply_request': 'supply_request',
                'supply_order': 'supply_order',
            },
            documentIcons: {
                'lieferschein': 'truck',
                'return_note': 'arrow-left',
                'product_picker': 'boxes',
                'shipping_label': ['fab', 'dhl'],
                'return': ['fab', 'dhl'],
                'combine': 'puzzle-piece',
                'confirm': 'info',
                'dhl_customs': ['fab', 'dhl'],
                'remind': 'bell',
                'supply_request': 'question',
                'supply_order': 'dolly',
                'gutschrift': 'coins',
            },
        }
    },
    mounted: function () {
        this.$root.$on('bv::dropdown::show', (bvEvent, kek) => {
            if (document.getElementById(bvEvent.componentId).classList.contains('dropdown-child')) {
                this.isDropdownChildVisible = true;
            }
        })
        this.$root.$on('bv::dropdown::hide', bvEvent => {
            if (document.getElementById(bvEvent.componentId).classList.contains('dropdown-child')) {
                this.isDropdownChildVisible = false;
            }
            if (this.isDropdownChildVisible) {
                bvEvent.preventDefault()
            }
        })
    },
    methods: {
        ...mapGetters('CommonData', ['getSettings', 'getWorkflowTriggers', 'getPreviewDocuments']),
        getTriggers() {
            let actions = []
            this.getWorkflowTriggers()['offering'].forEach(trigger => {
                actions.push({
                    title: 'Trigger: ' + trigger.name,
                    icon: 'play',
                    click: () => {
                        this.loading = true

                        this.$store.dispatch('Workflow/executeTrigger', {
                            id: trigger.id,
                            ids: [this.data.offering.id],
                            source: 'selected',
                            type: 'sales',
                        })
                            .then((response) => {
                                if (response.data.text) {
                                    this.$root.showMessage('Processing...', response.data.text, 'info')
                                    this.$root.$children[0].showJobsToast(response.data.result.batch_group)
                                }
                            })
                            .finally(() => {
                                this.loading = false
                            })
                    },
                },);
            })

            return actions
        },
        reSyncOrder() {
            let sendData = {
                ids: [this.data.offering.id],
                source: 'selected',
                type: 'sales',
            }

            this.$store.dispatch('Sales/reSyncOrders', sendData)
                .then((response) => {
                    this.commonJobsSave(response)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        getPreviewDocumentTypes() {
            if (this.formType == 'supply') {
                return ['supply_request', 'supply_order']
            }
            return this.getPreviewDocuments()
        },
        offeringReady() {
            this.$store.dispatch('Offering/offeringReady', {
                id: this.data.offering.id,
            })
                .finally(() => {
                    this.refreshTable()
                })
        },
        assignStocks() {
            this.$store.dispatch('Offering/assignStocks', {id: this.data.offering.id})
                .finally(() => {
                    this.refreshTable()
                })
        },
        generateSupplyRequest() {
            this.$store.dispatch('Offering/generateSupplyRequests', {id: this.data.offering.id})
                .finally(() => {
                    this.refreshTable()
                })
        },
        canDeleteOffering() {
            if (this.data.offering.all_positions.length || this.data.invoices.length || this.data.offerings.length) {
                return false
            }

            Object.keys(this.data.documents).forEach(doc_type => {
                if (this.data.documents[doc_type].url) {
                    return false
                }
            })

            return true
        },
        deleteOffering() {
            this.$store.dispatch('Offering/deleteOffering', this.data.offering.id)
                .finally(() => {
                    this.$root.showMessage(
                        this.$t('common.form.title.success'),
                        this.$t('common.toasts.deleted'),
                        'success'
                    )
                    this.$root.$children[0].closeModal(this.$parent.$parent.$parent.$parent.$refs['sidebar'])
                })
        },
        getDocument(type) {
            if (this.inputVal[type].url) {
                this.$root.openDocument(this.inputVal[type].name)
            } else {
                if (!['lieferschein', 'return_note', 'product_picker', 'shipping_label', 'dhl_customs', 'return', 'combine', 'kva', 'confirm', 'supply_request', 'supply_order'].includes(type)) {
                    this.$root.$children[0].openModal('document-prepare-modal', {
                        id: this.offeringId,
                        type: type
                    }, this.shown(type))
                } else {
                    this.easyDocumentGenerate(type)
                }

            }
        },
        easyDocumentGenerate(type) {
            this.$store.dispatch('Invoice/generateDocument', {id: this.offeringId, type: type})
                .then((response) => {
                    this.shown(type)
                })
                .finally(() => {
                })
        },
        canCancelInvoice(offering) {
            let result = false;
            if (typeof offering != "undefined" && offering.allinvoices.length > 0) {
                for (let item of offering.allinvoices) {
                    if (!item.iscanceled && !item.bezahldatum) {
                        result = true;
                    }
                }
            }
            return result;
        },
        getCancelInvoiceId(offering) {
            let id = 0;
            if (offering.allinvoices.length > 0) {
                for (let item of offering.allinvoices) {
                    if (!item.iscanceled && !item.bezahldatum) {
                        id = item.id;
                        break;
                    }
                }
            }
            return id;
        },
        cancelInvoice(offering) {
            let id = this.getCancelInvoiceId(offering);
            if (id) {
                this.$root.$children[0].openModal('invoice-cancel-modal', [id], this.shown, {width: '800px'});
            }
        },
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    }
}
</script>