function getDefaultState() {
    return {
        sftp_profile: {},
        sftp_profiles: [],
    }
}

const getters = {
    getSftpProfile: state => state.sftp_profile,
    getSftpProfiles: state => state.sftp_profiles,
}

const actions = {
    fetchSftpProfiles({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/sft/profiles', {'skip_loading': true})
                .then(response => {
                    commit('setSftpProfiles', response.data)
                    resolve(response)
                })
        })
    },
    fetchSftpProfile({commit}, id) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/sft/profile/' + id, {'skip_loading': true})
                .then(response => {
                    commit('setSftpProfile', response.data)
                    resolve(response)
                })
        })
    },
    deleteSftpProfile({dispatch}, id) {
        return new Promise((resolve) => {
            window.axios.delete(window.apiUrl + '/sft/profiles/' + id).then((response) => {
                resolve(response);
            })
        })
    },
    saveSftpProfile({commit}, profile) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/sft/profile/' + (profile ? profile.id : 0), profile, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
}

const mutations = {
    setSftpProfiles(state, response) {
        state.sftp_profiles = response.sftp_profiles
    },
    setSftpProfile(state, response) {
        state.sftp_profile = response.sftp_profile
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}