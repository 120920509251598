import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import {iconsSet as icons} from './assets/icons/icons.js'
import store from './store/index'
import i18n from './i18n'
import {mapGetters} from 'vuex'
import FlowChart from 'flowchart-vue'

const $S = require('scriptjs');

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'
import CustomCSidebarNavItem from './custom/CustomCSidebarNavItem'
import CustomCSidebarNavDropdown from './custom/CustomCSidebarNavDropdown'
// import { fab } from '@fortawesome/free-brands-svg-icons'
import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue'
import VueConfirmDialog from "vue-confirm-dialog"
import Multiselect from 'vue-multiselect'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import JsonViewer from 'vue-json-viewer'

import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'

import ZwEvents from './components/ZwEvents'
import ZwSidebar from './components/ZwSidebar'
import ZwCheckboxGroup from './components/ZwCheckboxGroup'
import ZwInputGroup from './components/ZwInputGroup'
import ZwModelSelectorGroup from './components/ZwModelSelectorGroup'
import ZwPhoneGroup from './components/ZwPhoneGroup'
import ZwCkeditor from './components/ZwCkeditor'
import ZwSwitchGroup from './components/ZwSwitchGroup'
import ZwMultiselectGroup from './components/ZwMultiselectGroup'
import ZwTextareaGroup from './components/ZwTextareaGroup'
import ZwFileGroup from './components/ZwFileGroup'
import ZwSelectGroup from './components/ZwSelectGroup'
import ZwMedia from './components/ZwMedia'
import CustomSlot from './components/CustomSlot'
import ZwFilesGroup from './components/ZwFilesGroup'
import ZwFilesUploadGroup from './components/ZwFilesUploadGroup'
import CustomField from './components/CustomField'
import TableFilters from './bundles/erika_common_bundle/TableFilters'
import Todos from './components/Todos'
import BackButton from './components/BackButton'
import TablePagination from './bundles/erika_common_bundle/TablePagination'
import SearchField from './components/SearchField'

import RemoveConfirm from './plugins/RemoveConfirm'
import RemoveConfirmAuth from './plugins/RemoveConfirmAuth'
import CreateConfirm from './plugins/CreateConfirm'
import UnpaidConfirm from './plugins/UnpaidConfirm'
import CancelConfirm from './plugins/CancelConfirm'
import UncancelConfirm from './plugins/UncancelConfirm'
import ChangeConfirm from './plugins/ChangeConfirm'
import Helpers from './plugins/Helpers'
import "./filters/filters"

import datePicker from 'vue-bootstrap-datetimepicker'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'

import 'vue-multiselect/dist/vue-multiselect.min.css'

import CKEditor from 'ckeditor4-vue'

import {
    ValidationObserver,
    ValidationProvider,
    extend,
    localize,
} from "vee-validate";
import en from 'vee-validate/dist/locale/en.json'
import ru from 'vee-validate/dist/locale/ru.json'
import * as rules from "vee-validate/dist/rules"

// import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import {fas} from '@fortawesome/free-solid-svg-icons'
import {fab} from '@fortawesome/free-brands-svg-icons'
import axios from 'axios'
import VueAxios from 'vue-axios'

import ZwDateGroup from './components/ZwDateGroup'
import ZwTimeGroup from './components/ZwTimeGroup'
import ZwNewTimeGroup from './components/ZwNewTimeGroup'
import {localeChanged} from "vee-validate";
import moment from 'moment'
import ZwTable from "@/bundles/erika_common_bundle/ZwTable";
import Settings from "./views/Settings";
import ZwNewDateGroup from "@/components/ZwNewDateGroup.vue";

library.add(fas)
library.add(fab)

Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});

const dateFormat = (value, data) => {
    return moment(value, data[0], true).isValid()
}

extend('date_format', dateFormat);

localize({
    en,
    ru
});

if (localStorage.locale == undefined) {
    localStorage.locale = 'de';
}

localize(localStorage.locale ?? 'en');

Vue.use(VueConfirmDialog)

Vue.component('CustomCSidebarNavItem', CustomCSidebarNavItem)
Vue.component('CustomCSidebarNavDropdown', CustomCSidebarNavDropdown)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

Vue.component("ValidationObserver", ValidationObserver)
Vue.component("ValidationProvider", ValidationProvider)

Vue.component('multiselect', Multiselect)
Vue.component('treeselect', Treeselect)
Vue.component('vue-typeahead-bootstrap', VueTypeaheadBootstrap)

Vue.component('zw-sidebar', ZwSidebar)
Vue.component('zw-events', ZwEvents)
Vue.component('zw-checkbox-group', ZwCheckboxGroup)
Vue.component('zw-select-group', ZwSelectGroup)
Vue.component('zw-media', ZwMedia)
Vue.component('custom-slot', CustomSlot)
Vue.component('zw-input-group', ZwInputGroup)
Vue.component('zw-phone-group', ZwPhoneGroup)
Vue.component('zw-ckeditor', ZwCkeditor)
Vue.component('zw-model-selector-group', ZwModelSelectorGroup)

Vue.component('zw-switch-group', ZwSwitchGroup)
Vue.component('zw-file-group', ZwFileGroup)
Vue.component('zw-files-group', ZwFilesGroup)
Vue.component('zw-files-upload-group', ZwFilesUploadGroup)
Vue.component('zw-textarea-group', ZwTextareaGroup)
Vue.component('zw-date-group', ZwDateGroup)
Vue.component('zw-new-date-group', ZwNewDateGroup)
Vue.component('zw-time-group', ZwTimeGroup)
Vue.component('zw-new-time-group', ZwNewTimeGroup)
Vue.component('zw-multiselect-group', ZwMultiselectGroup)
Vue.component('table-pagination', TablePagination)
Vue.component('custom-field', CustomField)
Vue.component('search-field', SearchField)
Vue.component('zw-table', ZwTable)

Vue.component('table-filters', TableFilters)
Vue.component('todos', Todos)
Vue.component('back-button', BackButton)
Vue.component('vue-dropzone', vue2Dropzone)

window.$ = window.jQuery = require('jquery');

$.extend(true, $.fn.datetimepicker.defaults, {
    format: 'DD.MM.YYYY',
    icons: {
        previous: 'custom-button-previous',
        next: 'custom-button-next',
        clear: 'custom-button-clear',
    }
});

Vue.component('date-picker', datePicker);
Vue.component('json-viewer', JsonViewer);

let subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;

if (subdomain) {
    window.apiUrl = window.location.protocol + '//' + subdomain + '' + '.' + process.env.VUE_APP_ENDPOINT + '/api'
} else {
    window.apiUrl = localStorage.getItem('apiUrl')
}

window.apiToken = localStorage.getItem('apiToken') ?? process.env.VUE_APP_API_TOKEN
window.apiUserToken = localStorage.getItem('apiUserToken') ?? process.env.VUE_APP_USER_TOKEN

window.store = store

window.axios = axios
window.axios.defaults.headers = {
    Authorization: `Bearer ${window.apiToken}`,
    UserAuthToken: `${window.apiUserToken}`
}

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueAxios, window.axios)
Vue.use(CKEditor)
Vue.use(RemoveConfirm)
Vue.use(RemoveConfirmAuth)
Vue.use(CreateConfirm)
Vue.use(UnpaidConfirm)
Vue.use(CancelConfirm)
Vue.use(UncancelConfirm)
Vue.use(ChangeConfirm)
Vue.use(Helpers)
Vue.use(FlowChart)

Vue.prototype.$log = console.log.bind(console)

Vue.component('zw-date-group', ZwDateGroup)


window.axios.defaults.headers['Accept-Language'] = localStorage.locale
window.axios.get(window.apiUrl + '/locales/messages').then(response => {
    i18n.mergeLocaleMessage('en', response.data.en)
    if (localStorage.locale != 'en') {
        i18n.mergeLocaleMessage(localStorage.locale, response.data[localStorage.locale])
    }
    i18n.locale = localStorage.locale

    localeChanged()
    new Vue({
        el: '#app',
        router,
        store,
        i18n,
        icons,
        template: '<App/>',
        components: {
            App
        },
        data() {
            return {
                pluginsData: {},
                simpleMode: false,
            }
        },
        methods: {
            ...mapGetters('CommonData', ['getPermissions', 'getPlugins', 'getPluginsCss', 'getMe', 'getModules', 'isImpersonated', 'isSimpleMode']),
            showMessage(title, content, variant = 'success', href = '') {
                this.$bvToast.toast(content, {
                    href,
                    title,
                    variant,
                    solid: true,
                    static: true,
                    toaster: 'toaster',
                })
            },
            copyToClipboard(text) {
                if (navigator.clipboard && navigator.clipboard.writeText) {
                    navigator.clipboard.writeText(text);
                } else {
                    var tempInput = document.createElement('input');
                    tempInput.setAttribute('value', text);
                    document.body.appendChild(tempInput);
                    tempInput.select();
                    tempInput.setSelectionRange(0, 99999);
                    document.execCommand('copy');
                }
            },
            pluginHasAccess(pluginName, action = null) {
                if (this.getMe()) {
                    if (this.getMe().role == 'admin') {
                        if (this.getPermissions()[pluginName] == undefined) {
                            return true // is admin & permissions array is empty
                        } else {
                            return this.getPermissions()[pluginName] !== undefined && this.getPermissions()[pluginName].includes(action)
                        }
                    } else {
                        return this.getPermissions()[pluginName] !== undefined && this.getPermissions()[pluginName].includes(action)
                    }
                }
            },
            hasAccess(module, action = null) {
                if (this.$root.simpleMode) {
                    let allowedModules = [null, 'header', 'dashboard', 'customers', 'articles', 'sales', 'multichannel', 'crm', 'crm-customers'];

                    if (!allowedModules.includes(module)) {
                        return false
                    }
                }
                if (module == null || module == 'languages' || module == 'plugin') {
                    return true
                }
                if (!this.getModules().includes(module)) {
                    return false
                }

                if (action == null) {
                    return this.getPermissions()[module] !== undefined && this.getPermissions()[module].length
                } else {
                    return this.getPermissions()[module] !== undefined && this.getPermissions()[module].includes(action)
                }
            },
            downloadDocument(name, type = 'invoices') {
                window.open(this.getDocumentUrl(name, type, false), '_blank');
            },
            openDocument(name, type = 'invoices') {
                window.open(this.getDocumentUrl(name, type), '_blank').focus();
            },
            getDocumentUrl(name, type = 'invoices', open = true) {
                if (type == 'invoices') {
                    if (name && !name.endsWith('.pdf')) {
                        name += '.pdf'
                    }
                }
                const routeData = this.$router.resolve({name: open ? 'Open' : 'Download', params: {type, name}})
                return routeData.href
            },
            getColumns(table) {
                const tables = {
                    'common': {
                        'created_at': () => import('./columns/common/date_time'),
                        'updated_at': () => import('./columns/common/date_time'),
                        'date_time': () => import('./columns/common/date_time'),
                    },
                    'offering': {
                        'lf_document': () => import('./columns/offering/lf_document'),
                        'invoice_documents': () => import('./columns/offering/invoice_documents'),
                        'number': () => import('./columns/offering/number'),
                        'status': () => import('./columns/offering/status'),
                        'fulfilment_status': () => import('./columns/offering/fulfilment_status'),
                        'shipping_status': () => import('./columns/offering/shipping_status'),
                        'notices': () => import('./columns/offering/notices'),
                        'stocks': () => import('./columns/offering/stocks'),
                        'netto': () => import('./columns/offering/netto'),
                        'entity_status': () => import('./columns/offering/entity_status'),
                        'payment_status': () => import('./columns/offering/payment_status'),
                        'invoice_status': () => import('./columns/offering/invoice_status'),
                        'brutto': () => import('./columns/offering/brutto'),
                        'sent_documents': () => import('./columns/offering/sent_documents'),
                        'customer': () => import('./columns/offering/customer'),
                        'documents': () => import('./columns/offering/documents'),
                        'total': () => import('./columns/offering/total'),
                        'positions': () => import('./columns/offering/positions'),
                        'positions_sku': () => import('./columns/offering/positions_sku'),
                        'positions_data': () => import('./columns/offering/positions_data'),
                        'api_sku': () => import('./columns/offering/api_sku'),
                        'expected_delivery_date': () => import('./columns/offering/expected_delivery_date'),
                        'source_offering': () => import('./columns/offering/source_offering'),
                        'paid_sum': () => import('./columns/offering/paid_sum'),
                        'unpaid_sum': () => import('./columns/offering/unpaid_sum'),
                        'sent_invoice': () => import('./columns/offering/sent_invoice'),
                        'contactPerson.customer.monthly_invoice': () => import('./columns/customer/monthly_invoice'),
                    },
                    'article': {
                        'sku': () => import('./columns/article/sku'),
                        'stocks': () => import('./columns/article/stocks'),
                        'custom_beds': () => import('./columns/article/custom_beds'),
                        'entity_status': () => import('./columns/article/entity_status'),
                        'purchase_price': () => import('./columns/article/purchase_price'),
                        'sales_price': () => import('./columns/article/sales_price'),
                        'base_price': () => import('./columns/article/base_price'),
                    },
                    'customer': {
                        'id': () => import('./columns/customer/id'),
                        'active': () => import('./columns/customer/active'),
                        'subscribed': () => import('./columns/customer/subscribed'),
                        'no_whatsapp': () => import('./columns/customer/no_whatsapp'),
                        'wrong_email': () => import('./columns/customer/wrong_email'),
                        'wrong_number': () => import('./columns/customer/wrong_number'),
                        'monthly_invoice': () => import('./columns/customer/monthly_invoice'),
                        'contact_persons': () => import('./columns/customer/contact_persons'),
                        'tags': () => import('./columns/customer/tags'),
                        'api_customer_id': () => import('./columns/customer/api_customer_id'),
                        'entity_status': () => import('./columns/customer/entity_status'),
                        'company': () => import('./columns/customer/company'),
                        'phones': () => import('./columns/customer/phones'),
                        'mobiles': () => import('./columns/customer/mobiles'),
                        'emails': () => import('./columns/customer/emails'),
                        'groups': () => import('./columns/customer/groups'),
                        'custom_sales': () => import('./columns/customer/custom_sales'),
                        'custom_gross_profit': () => import('./columns/customer/custom_gross_profit'),
                        'custom_cumulative_profit': () => import('./columns/customer/custom_cumulative_profit'),
                        'todos': () => import('./columns/customer/todos'),
                        'documents': () => import('./columns/customer/documents'),
                        'contracts': () => import('./columns/customer/contracts'),
                    },
                    'contact-persons': {
                        'id': () => import('./columns/contact_persons/id'),
                        'mobile': () => import('./columns/contact_persons/mobile'),
                        'phone': () => import('./columns/contact_persons/phone'),
                    },
                    'customer-contracts': {
                        'entity_status': () => import('./columns/customer-contracts/entity_status'),
                    },
                    'invoice': {
                        'brutto': () => import('./columns/invoice/brutto'),
                        'customer': () => import('./columns/invoice/customer'),
                        'entity_status': () => import('./columns/invoice/entity_status'),
                        'mails': () => import('./columns/invoice/mails'),
                        'netto': () => import('./columns/invoice/netto'),
                        'vats_sum': () => import('./columns/invoice/vats_sum'),
                        'vats': () => import('./columns/invoice/vats'),
                        'number': () => import('./columns/invoice/number'),
                        'payments': () => import('./columns/invoice/payments'),
                        'project': () => import('./columns/invoice/project'),
                        'total_paid': () => import('./columns/invoice/total_paid'),
                    },
                    'bank_transactions': {
                        'amount': () => import('./columns/bank_transactions/amount'),
                        'invoice_name': () => import('./columns/bank_transactions/invoice_name'),
                        'json': () => import('./columns/bank_transactions/json'),
                        'entity_status': () => import('./columns/bank_transactions/entity_status'),
                        'offering_name': () => import('./columns/bank_transactions/offering_name'),
                        'incoming_invoice_name': () => import('./columns/bank_transactions/incoming_invoice_name'),
                        'description': () => import('./columns/bank_transactions/description'),
                    },
                    'incoming_invoice': {
                        'barkonto': () => import('./columns/incoming_invoice/barkonto'),
                        'brutto_amount': () => import('./columns/incoming_invoice/brutto_amount'),
                        'cash': () => import('./columns/incoming_invoice/cash'),
                        'entity_status': () => import('./columns/incoming_invoice/entity_status'),
                        'gf_document': () => import('./columns/incoming_invoice/gf_document'),
                        'id': () => import('./columns/incoming_invoice/id'),
                        'media': () => import('./columns/incoming_invoice/media'),
                        'net_amount': () => import('./columns/incoming_invoice/net_amount'),
                        'project': () => import('./columns/incoming_invoice/project'),
                        'rechnungskonto': () => import('./columns/incoming_invoice/rechnungskonto'),
                        'total_amount': () => import('./columns/incoming_invoice/total_amount'),
                        'vat_amount': () => import('./columns/incoming_invoice/vat_amount'),
                        'vat_rate': () => import('./columns/incoming_invoice/vat_rate'),
                        'verified': () => import('./columns/incoming_invoice/verified'),
                        'bank_transfers': () => import('./columns/incoming_invoice/bank_transfers'),
                    },
                    'workflow_history': {
                        'workflow_id': () => import('./columns/workflow_history/workflow_id'),
                        'workflow_name': () => import('./columns/workflow_history/workflow_name'),
                        'offerings': () => import('./columns/workflow_history/offerings'),
                        'customers': () => import('./columns/workflow_history/customers'),
                    }
                }

                let data = {}
                Object.keys(tables).forEach(key => {
                    if (key == table) {
                        Object.keys(tables[key])
                            .forEach((col_name) => {
                                data[col_name] = tables[key][col_name]
                            })
                    } else {

                        Object.keys(tables[key])
                            .forEach((col_name) => {
                                data[key + '.' + col_name] = tables[key][col_name]

                                let relations = ['contactPerson', 'offering']
                                relations.forEach((relation) => {
                                    data[relation + '.' + key + '.' + col_name] = tables[key][col_name]
                                })
                            })
                    }
                })

                return data
            },
            afterSettingsUpdate(refresh = false, path = null, tab = null) {
                this.$store.dispatch('CommonData/fetchCommonData').then(() => {
                    if (refresh) {
                        this.$router.push({path: path, query: {tab: tab, time: Date.now()}})
                    }
                })
            },
        },
        created() {
            const languages = this.$store.dispatch('I18NStore/fetchLanguages')
            let promises = [languages]

            let fetchCommonData = ['/login', '/forgot', '/activate', '/sign', '/unsubscribe'].filter(path => this.$router.currentRoute.path.startsWith(path)).length == 0
            if (fetchCommonData) {
                const commonData = this.$store.dispatch('CommonData/fetchCommonData')
                const exportOptions = this.$store.dispatch('Export/fetchOptions')
                promises.push(exportOptions);
                promises.push(commonData);
            }

            Promise.all(promises)
                .catch((error) => {
                    if (this.$router.currentRoute.path != '/login') {
                        this.$router.push({path: `/login`})
                    }
                })
                .finally(() => {
                    this.simpleMode = this.isSimpleMode()
                    let subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
                    let plugins = []
                    this.getPlugins().forEach(pluginName => {
                        plugins.push(pluginName)
                        $S(window.apiUrl + '/plugins/' + subdomain + '/' + pluginName, pluginName)

                        Object.keys(this.getPluginsCss()).forEach(pluginN => {
                            let sheet = document.createElement('style')
                            sheet.innerHTML = this.getPluginsCss()[pluginN]['css'];
                            document.body.appendChild(sheet);
                        })
                    })

                    $S.ready(plugins, () => {
                        const TheContainer = () => import('@/containers/TheContainer')

                        const Dashboard = () => import('@/views/Dashboard')
                        const Sales = () => import('@/views/Sales')
                        const Ckeditor = () => import('@/views/Ckeditor')
                        const SalesAttachments = () => import('@/views/SalesAttachments')
                        const GlobalAttachments = () => import('@/views/GlobalAttachments')
                        const Customers = () => import('@/views/Customers')
                        const News = () => import('@/views/News')
                        const ContractSettings = () => import('@/views/ContractSettings')
                        const CustomerContract = () => import('@/views/CustomerContract')
                        const Crm = () => import('@/views/Crm')
                        const CrmContracts = () => import('@/views/CrmContracts')
                        const CrmCustomers = () => import('@/views/CrmCustomers')
                        const CrmTodos = () => import('@/views/CrmTodos')
                        const CrmNotifications = () => import('@/views/CrmNotifications')
                        const Articles = () => import('@/views/Articles')
                        const ArticlesInventory = () => import('@/views/ArticlesInventory')
                        const ArticlesInventoryList = () => import('@/views/ArticlesInventoryList')
                        const Deposits = () => import('@/views/Deposits')
                        const IncomingInvoices = () => import('@/views/IncomingInvoices')
                        const Liabilities = () => import('@/views/Liabilities')
                        const MonthlyInvoices = () => import('@/views/MonthlyInvoices')
                        const CashInvoices = () => import('@/views/CashInvoices')
                        const BankTransfers = () => import('@/views/BankTransfers')
                        const BankDebitTransfers = () => import('@/views/BankDebitTransfers')
                        const ProfitAndLoss = () => import('@/views/ProfitAndLoss')
                        const Invoices = () => import('@/views/Invoices')
                        const Insurance = () => import('@/views/Insurance')
                        const Transfers = () => import('@/views/Transfers')
                        const Hotels = () => import('@/views/Hotels')
                        const CustomFields = () => import('@/views/CustomFields')
                        const MultiChannel = () => import('@/views/modules/multichannel/MultiChannel')
                        const Jobs = () => import('@/views/Jobs')
                        const FailedJobs = () => import('@/views/FailedJobs')
                        const ExportHistory = () => import('@/views/ExportHistory')
                        // const CombineFiles = () => import('@/views/CombineFiles')
                        const ApiLogs = () => import('@/views/ApiLogs')
                        const Shipments = () => import('@/views/Shipments')
                        const Login = () => import('@/views/pages/Login')
                        const Logout = () => import('@/views/Logout')
                        const Forgot = () => import('@/views/pages/Forgot')
                        const Sign = () => import('@/views/pages/Sign.vue')
                        const Unsubscribe = () => import('@/views/pages/Unsubscribe.vue')
                        const Recover = () => import('@/views/pages/Recover')
                        const Activate = () => import('@/views/pages/Activate')
                        const Download = () => import('@/views/pages/Download')
                        const Refresh = () => import('@/views/Refresh')
                        const Mails = () => import('@/views/Mails')
                        const CombineFiles = () => import('@/views/CombineFiles')
                        const Search = () => import('@/views/pages/Search')

                        const SettingsGlobal = () => import('@/views/settings/_global')
                        const SettingsGeneral = () => import('@/views/settings/_general')
                        const SettingsAttributes = () => import('@/views/settings/_attributes')
                        const SettingsDocuments = () => import('@/views/settings/_documents')
                        const SettingsLanguages = () => import('@/views/settings/_languages')
                        const SettingsShipping = () => import('@/views/settings/_shipping')
                        const SettingsStocks = () => import('@/views/settings/_stocks')
                        const SettingsUsers = () => import('@/views/settings/_users')
                        const SettingsEmails = () => import('@/views/settings/_emails')
                        const SettingsStatuses = () => import('@/views/settings/_statuses')
                        const SettingsPaymentmethods = () => import('@/views/settings/_paymentmethods')
                        const SettingsCrm = () => import('@/views/settings/_crm')
                        const SettingsWorkflows = () => import('@/views/settings/_workflows')
                        const SettingsKB = () => import('@/views/settings/_kb')
                        const SettingsChannels = () => import('@/views/settings/_channels')
                        const SettingsCategories = () => import('@/views/settings/_categories')
                        const SettingsModules = () => import('@/views/settings/_modules')
                        const SettingsArticles = () => import('@/views/settings/_articles')
                        const SettingsBank = () => import('@/views/settings/bank')
                        const SettingsCustomer = () => import('@/views/settings/_customer')
                        const SettingsExport = () => import('@/views/settings/_export.vue')

                        const modules = {
                            dashboard: [{
                                path: '/dashboard',
                                name: 'Dashboard',
                                component: Dashboard
                            }],
                            sales: [{
                                path: '/sales',
                                name: 'Sales',
                                component: Sales
                            }],
                            'global-attachments': [{
                                path: '/global-attachments',
                                name: 'Global attachments',
                                component: GlobalAttachments
                            }],
                            'sales-attachments': [{
                                path: '/sales-attachments',
                                name: 'Sales attachments',
                                component: SalesAttachments
                            }],
                            'combine-files': [{
                                path: '/combine-files',
                                name: 'Combine files',
                                component: CombineFiles
                            }],
                            supply: [{
                                path: '/supply',
                                name: 'Supply',
                                component: Sales,
                                meta: {
                                    type: 'supply'
                                }
                            }],
                            'supply-offers': [{
                                path: '/supply-offers',
                                name: 'Supply offers',
                                component: Sales,
                                meta: {
                                    type: 'supply'
                                }
                            }],
                            returns: [{
                                path: '/returns',
                                name: 'Returns',
                                component: Sales,
                                meta: {
                                    type: 'return'
                                }
                            }],
                            customers: [{
                                path: '/customers',
                                name: 'Customers',
                                component: Customers
                            }],
                            'crm': [{
                                path: '/crm',
                                name: 'CRM',
                                component: Crm,
                                meta: {
                                    type: 'crm'
                                }
                            }],
                            'crm-contracts': [{
                                path: '/crm-contracts',
                                name: 'CrmContracts',
                                component: CrmContracts,
                            }],
                            'crm-aftersale': [{
                                path: '/crm/done',
                                name: 'CRM',
                                component: Crm,
                                meta: {
                                    type: 'aftersale'
                                }
                            }],
                            'contract': [{
                                path: '/customer-contract',
                                name: 'CustomerContract',
                                component: CustomerContract
                            }],
                            'news': [{
                                path: '/news',
                                name: 'news',
                                component: News
                            }],
                            'contract-settings': [{
                                path: '/contract-settings',
                                name: 'ContractSettings',
                                component: ContractSettings
                            }],
                            'crm-customers': [{
                                path: '/crm/customers',
                                name: 'CRM',
                                component: CrmCustomers
                            }],
                            'crm-todos': [{
                                path: '/crm/todos',
                                name: 'Todos',
                                component: CrmTodos
                            }],
                            'crm-notifications': [{
                                path: '/crm/notifications',
                                name: 'CRM',
                                component: CrmNotifications
                            }],
                            'articles': [{
                                path: '/articles',
                                name: 'Articles',
                                component: Articles
                            }],
                            'articles-inventory': [{
                                path: '/articles/inventory',
                                name: 'Inventory',
                                component: ArticlesInventory
                            }, {
                                path: '/articles/inventory-list',
                                name: 'InventoryList',
                                component: ArticlesInventoryList
                            }],
                            'invoice': [{
                                path: '/invoice',
                                name: 'Invoices',
                                component: Invoices
                            }],
                            'incoming-invoice': [{
                                path: '/incoming-invoice',
                                name: 'IncomingInvoices',
                                component: IncomingInvoices
                            }],
                            'liabilities': [{
                                path: '/liabilities',
                                name: 'Liabilities',
                                component: Liabilities
                            }],
                            'profit-and-loss': [{
                                path: '/profit-and-loss',
                                name: 'ProfitAndLoss',
                                component: ProfitAndLoss
                            }],
                            'monthly-invoices': [{
                                path: '/monthly-invoices',
                                name: 'MonthlyInvoices',
                                component: MonthlyInvoices
                            }],
                            'cash': [{
                                path: '/cash-invoice',
                                name: 'CashInvoices',
                                component: CashInvoices
                            }],
                            'bank-transfers': [{
                                path: '/bank-transfers',
                                name: 'BankTransfers',
                                component: BankTransfers
                            }],
                            'bank-transfers-debit': [{
                                path: '/bank-transfers-debit',
                                name: 'BankDebitTransfers',
                                component: BankDebitTransfers
                            }],
                            'transfers': [{
                                path: '/transfers',
                                name: 'Transfers',
                                component: Transfers
                            }],
                            'hotels': [{
                                path: '/hotels',
                                name: 'Hotels',
                                component: Hotels
                            }],
                            'deposits': [{
                                path: '/deposits',
                                name: 'Deposits',
                                component: Deposits
                            }],
                            'insurance': [{
                                path: '/insurance',
                                name: 'Insurance',
                                component: Insurance
                            }],
                            'custom-fields': [{
                                path: '/custom-fields',
                                name: 'Custom fields',
                                component: CustomFields,
                            }],
                            'jobs': [
                                {
                                    path: '/jobs',
                                    name: 'Jobs',
                                    component: Jobs,
                                },
                                {
                                    path: '/failed-jobs',
                                    name: 'Failed Jobs',
                                    component: FailedJobs,
                                }],
                            'api-logs': [{
                                path: '/api-logs',
                                name: 'API logs',
                                component: ApiLogs,
                            }],
                            'mails': [{
                                path: '/mails',
                                name: 'Mails',
                                component: Mails,
                            }],
                            'export-history': [{
                                path: '/export-history',
                                name: 'Export History',
                                component: ExportHistory,
                            }],
                            'shipments': [{
                                path: '/shipments',
                                name: 'Shipments',
                                component: Shipments,
                            }],
                            'multichannel': [{
                                path: '/multichannel/:id',
                                name: 'Multichannel',
                                component: MultiChannel,
                            }],

                            'settings': [
                                {
                                    path: '/search/:query',
                                    name: 'Search',
                                    component: Search
                                },
                                {
                                    path: '/settings/global',
                                    name: 'Settings',
                                    component: SettingsGlobal,
                                },
                                {
                                    path: '/settings/customer',
                                    name: 'Customer',
                                    component: SettingsCustomer,
                                },
                                {
                                    path: '/settings',
                                    name: 'General',
                                    component: SettingsGeneral,
                                },
                                {
                                    path: '/settings/general',
                                    name: 'General',
                                    component: SettingsGeneral,
                                },
                                {
                                    path: '/settings/attributes',
                                    name: 'Attributes',
                                    component: SettingsAttributes,
                                },
                                {
                                    path: '/settings/documents',
                                    name: 'Documents',
                                    component: SettingsDocuments,
                                },
                                {
                                    path: '/settings/export',
                                    name: 'Export',
                                    component: SettingsExport,
                                },
                                {
                                    path: '/settings/languages',
                                    name: 'Documents',
                                    component: SettingsLanguages,
                                },
                                {
                                    path: '/settings/channels',
                                    name: 'Channels',
                                    component: SettingsChannels,
                                },
                                {
                                    path: '/settings/emails',
                                    name: 'Emails',
                                    component: SettingsEmails,
                                },
                                {
                                    path: '/settings/statuses',
                                    name: 'Statuses',
                                    component: SettingsStatuses,
                                },
                                {
                                    path: '/settings/paymentmethods',
                                    name: 'Paymentmethods',
                                    component: SettingsPaymentmethods,
                                },
                                {
                                    path: '/settings/crm',
                                    name: 'CRM',
                                    component: SettingsCrm,
                                },
                                {
                                    path: '/settings/stocks',
                                    name: 'Stocks',
                                    component: SettingsStocks,
                                },
                                {
                                    path: '/settings/shipping',
                                    name: 'Documents',
                                    component: SettingsShipping,
                                },
                                {
                                    path: '/settings/users',
                                    name: 'Users',
                                    component: SettingsUsers,
                                },
                                {
                                    path: '/settings/workflows',
                                    name: 'Workflows',
                                    component: SettingsWorkflows,
                                },
                                {
                                    path: '/settings/articles',
                                    name: 'articles',
                                    component: SettingsArticles,
                                },
                                {
                                    path: '/settings/categories',
                                    name: 'Categories',
                                    component: SettingsCategories,
                                },
                                {
                                    path: '/settings/kb',
                                    name: 'KB',
                                    component: SettingsKB,
                                },
                                {
                                    path: '/settings/modules',
                                    name: 'Modules',
                                    component: SettingsModules,
                                },
                                {
                                    path: '/settings/bank',
                                    name: 'Bank',
                                    component: SettingsBank,
                                },
                            ],
                        }

                        modules['plugin'] = []
                        plugins.forEach(pluginName => {
                            if (window[pluginName] && window[pluginName].methods.getRoutes) {
                                let pluginRoutes = window[pluginName].methods.getRoutes()
                                let pluginRoutesPermissions = window[pluginName].methods.getRoutesPermissions ? window[pluginName].methods.getRoutesPermissions() : []
                                Object.keys(pluginRoutes).forEach(key => {

                                    if (this.pluginHasAccess(pluginName, pluginRoutesPermissions[key] || 'read')) {
                                        modules['plugin'].push({
                                            path: key,
                                            name: 'Plugin' + pluginName,
                                            component: Vue.component(pluginName, window[pluginName].components[pluginRoutes[key]]),
                                        })
                                    }
                                })
                            }
                            if (window[pluginName] && window[pluginName].methods.getModals) {
                                Object.keys(window[pluginName].methods.getModals()).forEach(key => {
                                    this.$children[0].modalsMap[key] = window[pluginName].components[window[pluginName].methods.getModals()[key]]
                                });
                            }
                        })

                        let children = []
                        let firstHasAccess = null

                        Object.keys(modules).forEach(key => {
                            if (this.hasAccess(key)) {
                                modules[key].forEach(route => {
                                    if (firstHasAccess == null) {
                                        firstHasAccess = route.component
                                    }
                                    children.push(route)
                                })
                            } else {
                            }
                        })
                        if (!firstHasAccess) {
                            if (['/login', '/forgot', '/activate', '/sign', '/unsubscribe'].filter(path => this.$router.currentRoute.path.startsWith(path)).length == 0) {
                                this.$router.push({path: `/login`})
                            }
                        }

                        children.push({
                            path: '/refresh',
                            name: 'Refresh',
                            component: Refresh,
                        })
                        children.push({
                            path: '/logout',
                            name: 'Logout',
                            component: Logout,
                        })

                        children.push({path: "*", component: firstHasAccess})

                        this.$router.addRoutes([
                            {
                                path: '/download/:type/:name',
                                name: 'Download',
                                component: Download,
                                meta: {
                                    open: false
                                }
                            },
                            {
                                path: '/open/:type/:name',
                                name: 'Open',
                                component: Download,
                                meta: {
                                    open: true
                                }
                            },
                            {
                                path: '/login',
                                name: 'Login',
                                component: Login,
                            },
                            {
                                path: '/login/:hash',
                                name: 'Login',
                                component: Login,
                            },
                            {
                                path: '/forgot',
                                name: 'Forgot',
                                component: Forgot,
                            },
                            {
                                path: '/forgot/:hash',
                                name: 'Recover',
                                component: Recover,
                            },
                            {
                                path: '/sign/:hash',
                                name: 'Sign',
                                component: Sign,
                            },
                            {
                                path: '/unsubscribe/:id/:hash',
                                name: 'Unsubscribe',
                                component: Unsubscribe,
                            },
                            {
                                path: '/activate/:hash',
                                name: 'Activate',
                                component: Activate,
                            },
                            {
                                path: '/ckeditor',
                                name: 'ckeditor',
                                component: Ckeditor,
                            },
                            {
                                path: '/',
                                redirect: '/customers',
                                name: 'Home',
                                component: TheContainer,
                                children: children
                            },
                        ])

                        plugins.forEach(pluginName => {
                            if (window[pluginName] && window[pluginName].methods.created) {
                                window[pluginName].methods.created(this.$root)
                            }
                        })
                    })
                })
        }
    })
})