<template>
    <zw-sidebar @shown="shown"
                :title="this.payload.id ? $t('common.form.title.editWorkflow')  + ' ('+ this.payload.id + ')' : $t('common.form.title.createWorkflow')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div>
                <b-row>
                    <b-col cols="3">
                        <zw-input-group v-model="form.title"
                                        name="title"
                                        :label-prefix="labelPrefix"
                                        size="s"
                        />
                    </b-col>
                    <b-col cols="3">
                        <zw-input-group v-model="form.description"
                                        name="description"
                                        :label-prefix="labelPrefix"
                                        size="s"
                        />
                    </b-col>
                    <b-col cols="3">
                        <zw-select-group v-model="form.trigger"
                                         name="trigger"
                                         :label-prefix="labelPrefix"
                                         validate="required"
                                         :options="triggers"
                                         :multiple="false"
                                         :custom-label="opt => triggers[opt]"
                                         size="s"
                        ></zw-select-group>
                    </b-col>
                    <b-col cols="1">
                        <zw-switch-group v-model="form.active"
                                         name="active"
                                         :label-prefix="labelPrefix"
                        ></zw-switch-group>
                    </b-col>
                    <template cols="12"
                              v-if="['status_change','before_document_generate','document_generate','offering_ready','fulfilment_status'].includes(form.trigger)">
                        <b-col cols="3">
                            <b-form-group :label="$t(labelPrefix +'api_source_id')">
                                <multiselect
                                    v-model="form.api_source_id"
                                    :options="Object.keys(sources)"
                                    :multiple="true"
                                    size="s"
                                    :custom-label="opt => sources[opt]">
                                </multiselect>
                            </b-form-group>
                        </b-col>
                        <b-col cols="3">
                            <zw-select-group v-model="form.error_status"
                                             :options="getStatuses() | optionsStatuses"
                                             name="error_status"
                                             size="s"
                                             :label-prefix="labelPrefix"
                                             validate="required"
                            ></zw-select-group>
                        </b-col>
                    </template>
                    <b-col cols="3">
                        <template v-if="form.trigger=='status_change'">
                            <zw-select-group v-model="form.status"
                                             :options="getStatuses() | optionsStatuses"
                                             name="status"
                                             :label-prefix="labelPrefix"
                                             validate="required"
                                             size="s"
                            ></zw-select-group>
                        </template>
                        <template v-if="form.trigger=='entity_status_change'">
                            <zw-select-group v-model="form.trigger_options.entity_type"
                                             :options="Object.keys(getEntityStatuses())"
                                             name="entity_type"
                                             :label-prefix="labelPrefix"
                                             validate="required"
                                             size="s"
                            ></zw-select-group>
                        </template>
                        <template v-if="form.trigger=='trigger'">
                            <zw-select-group v-model="form.trigger_options.trigger_id"
                                             :options="getTriggersOptions()"
                                             name="trigger"
                                             :label-prefix="labelPrefix"
                                             validate="required"
                                             size="s"
                            ></zw-select-group>
                        </template>
                        <template v-if="form.trigger=='fulfilment_status'">
                            <zw-select-group v-model="form.trigger_options.fulfilment_status"
                                             :options="getFulfilmentStatuses()"
                                             name="fulfilment_status"
                                             :label-prefix="labelPrefix"
                                             validate="required"
                                             size="s"
                            ></zw-select-group>
                        </template>
                        <template v-if="form.trigger=='cron'">
                            <zw-select-group v-model="form.trigger_options.time"
                                             :options="[
                                                 {value: 'daily', text: 'daily'},
                                                 {value: 'hourly', text: 'hourly'},
                                                 {value: 'every_ten_minutes', text: 'every_ten_minutes'},
                                                 {value: 'every_six_hours', text: 'every_six_hours'},
                                                 {value: 'every_four_hours', text: 'every_four_hours'},
                                                 {value: 'every_month', text: 'every_month'},
                                                 {value: 'every_six_months', text: 'every_six_months'},
                                                 {value: 'every_year', text: 'every_year'},
                                                 ]"
                                             name="time"
                                             :label-prefix="labelPrefix"
                                             validate="required"
                                             size="s"
                            ></zw-select-group>
                        </template>
                        <template v-if="form.trigger=='date'">
                            <b-row>
                                <b-col cols="3">
                                    <zw-select-group v-model="form.trigger_options.object"
                                                     :options="['offering','customer']"
                                                     name="object"
                                                     :label-prefix="labelPrefix"
                                                     validate="required"
                                                     size="s"
                                    ></zw-select-group>
                                </b-col>
                                <b-col cols="3">
                                    <zw-select-group v-model="form.trigger_options.attribute"
                                                     :options="form.trigger_options.object == 'customer' ? getCustomerAttributes() : getAttributes()"
                                                     name="attribute"
                                                     :label-prefix="labelPrefix"
                                                     validate="required"
                                                     size="s"
                                    ></zw-select-group>
                                </b-col>
                                <b-col cols="3">
                                    <zw-select-group v-model="form.trigger_options.date_value"
                                                     :options="getDateValues()"
                                                     name="date_value"
                                                     :label-prefix="labelPrefix"
                                                     validate="required"
                                                     size="s"
                                    ></zw-select-group>
                                </b-col>
                            </b-row>
                        </template>
                        <template v-if="form.trigger=='document_generate' || form.trigger=='before_document_generate'">
                            <b-form-group :label="$t(labelPrefix+'doc_types')">
                                <multiselect
                                    v-model="form.trigger_options.doc_types"
                                    :options="getDocumentTypes()"
                                    :multiple="true"
                                    size="s"
                                    :label-prefix="labelPrefix">
                                </multiselect>
                            </b-form-group>
                        </template>
                        <template v-if="(form.trigger=='document_uploaded' || form.trigger=='document_signed')">
                            <zw-switch-group v-model="form.trigger_options.by_customer"
                                             name="by_customer"
                                             :label-prefix="labelPrefix"
                            ></zw-switch-group>
                        </template>
                        <template v-if="form.trigger=='condition'">
                            <b-row>
                                <b-col cols="4">{{ $t('settings.shipping_rule.label.attribute') }}</b-col>
                                <b-col cols="3">{{ $t('settings.shipping_rule.label.condition') }}</b-col>
                                <b-col cols="3">{{ $t('settings.shipping_rule.label.value') }}</b-col>
                            </b-row>

                            <conditions v-model="form.trigger_options"></conditions>
                        </template>
                    </b-col>
                    <b-col cols="3" v-if="form.trigger=='entity_status_change'">
                        <zw-select-group v-model="form.status"
                                         v-if="form.trigger_options.entity_type"
                                         :options="entityStatuses(form.trigger_options.entity_type)"
                                         name="status"
                                         :label-prefix="labelPrefix"
                                         validate="required"
                                         size="s"
                        ></zw-select-group>
                    </b-col>
                </b-row>
                <b-row v-if="!source">
                    <b-col cols="12">
                        <b-dropdown class="mx-1"
                                    right
                                    variant="info"
                                    :text="$t('settings.workflow.button.conditions.title')"
                        >
                            <b-dropdown-item key="1"
                                             @click="addCondition('start')"
                            >
                                {{ $t('settings.workflow.button.conditions.start') }}
                            </b-dropdown-item>
                            <b-dropdown-item key="2"
                                             @click="addCondition('if')"
                            >
                                {{ $t('settings.workflow.button.conditions.if') }}
                            </b-dropdown-item>
                            <b-dropdown-item key="51"
                                             @click="addCondition('if-customer')"
                            >
                                {{ $t('settings.workflow.button.conditions.if_customer') }}
                            </b-dropdown-item>
                            <b-dropdown-item key="3"
                                             @click="addCondition('offering-selector')"
                            >
                                {{ $t('settings.workflow.button.conditions.offering_selector') }}
                            </b-dropdown-item>
                            <b-dropdown-item key="4"
                                             @click="addCondition('customer-selector')"
                            >
                                {{ $t('settings.workflow.button.conditions.customer_selector') }}
                            </b-dropdown-item>
                            <b-dropdown-item key="5"
                                             @click="addCondition('invoice-selector')"
                            >
                                {{ $t('settings.workflow.button.conditions.invoice_selector') }}
                            </b-dropdown-item>
                            <b-dropdown-item key="6"
                                             @click="addCondition('offering-to-customer')"
                            >
                                {{ $t('settings.workflow.button.conditions.offering_to_customer') }}
                            </b-dropdown-item>
                            <b-dropdown-item key="7"
                                             @click="addCondition('customer-to-offerings')"
                            >
                                {{ $t('settings.workflow.button.conditions.customer_to_offerings') }}
                            </b-dropdown-item>
                            <b-dropdown-item key="8"
                                             @click="addCondition('todo-selector')"
                            >
                                {{ $t('settings.workflow.button.conditions.todo_selector') }}
                            </b-dropdown-item>
                        </b-dropdown>
                        <b-dropdown class="mx-1"
                                    right
                                    variant="warning"
                                    :text="$t('settings.workflow.button.base_actions.title')"
                        >
                            <b-dropdown-item v-for="(action,index) in getBaseActions()"
                                             :key="index"
                                             @click="addBaseAction(action)"
                            >
                                {{ $t('settings.workflow.actions.' + action) }}
                            </b-dropdown-item>
                        </b-dropdown>

                        <b-dropdown class="mx-1"
                                    right
                                    variant="success"
                                    :text="$t('settings.workflow.button.actions.title')"
                        >
                            <b-dropdown-item v-for="(action,index) in getActions()"
                                             :key="index"
                                             @click="addAction(action)"
                            >
                                {{ $t('settings.workflow.actions.' + action) }}
                            </b-dropdown-item>
                        </b-dropdown>

                        <b-dropdown class="mx-1"
                                    right
                                    variant="success"
                                    :text="$t('settings.workflow.button.actions.customer_title')"
                        >
                            <b-dropdown-item v-for="(action,index) in getCustomerActions()"
                                             :key="index"
                                             @click="addAction(action)"
                            >
                                {{ $t('settings.workflow.actions.' + action) }}
                            </b-dropdown-item>
                        </b-dropdown>

                        <b-dropdown class="mx-1"
                                    right
                                    variant="success"
                                    :text="$t('settings.workflow.button.actions.todo_title')"
                        >
                            <b-dropdown-item v-for="(action,index) in getTodoActions()"
                                             :key="index"
                                             @click="addAction(action)"
                            >
                                {{ $t('settings.workflow.actions.' + action) }}
                            </b-dropdown-item>
                        </b-dropdown>

                        <b-button @click="clear()"
                                  variant="danger"
                                  class="mr-1 mr-2"
                        >
                            {{ $t('settings.workflow.button.clear') }}
                        </b-button>

                        <b-button variant="primary"
                                  class="ml-5"
                                  @click="openSource"
                        >
                            {{ $t('settings.workflow.button.source') }}
                        </b-button>

                        <div class="mt-2">
                            <div id="drawflow"></div>
                        </div>
                        <div class="bar-zoom text-right mb-3">
                            <b-button @click="editor.zoom_out()"
                                      variant="default"
                                      class="mr-1"
                            >
                                <font-awesome-icon icon="search-minus"/>
                            </b-button>
                            <b-button @click="editor.zoom_in()"
                                      variant="default"
                                      class="mr-1"
                            >
                                <font-awesome-icon icon="search-plus"/>
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
                <b-row v-else>
                    <b-col>
                        <b-textarea v-model="sourceJson" style="height: 400px"></b-textarea>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="12" class="text-sm-right">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>

    </zw-sidebar>
</template>

<script>

import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import Drawflow from "drawflow";
import styleDrawflow from 'drawflow/dist/drawflow.min.css'

import Vue from "vue";
import bus from './../../components/event-bus.js';
import StartCondition from "@/components/workflow/StartCondition";
import IfCondition from "@/components/workflow/IfCondition";
import CommonAction from "@/components/workflow/CommonAction";
import BaseAction from "@/components/workflow/BaseAction";
import {mapGetters} from "vuex";
import Conditions from "@/modals/offering/workflow-options/Conditions";
import Condition from "@/components/Condition";
import OfferingSelector from "@/components/workflow/OfferingSelector";
import CustomerSelector from "@/components/workflow/CustomerSelector";
import InvoiceSelector from "@/components/workflow/InvoiceSelector";
import TodoSelector from "@/components/workflow/TodoSelector";
import IfCustomerCondition from "@/components/workflow/IfCustomerCondition";
import OfferingToCustomer from "@/components/workflow/OfferingToCustomer";
import CustomerToOfferings from "@/components/workflow/CustomerToOfferings";

export default {
    name: 'WorkflowModal',
    components: {Condition, Conditions},
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            defaultForm: {
                status: '',
                setup: {},
                active: '0',
                api_source_id: [],
                trigger: 'status_change',
                trigger_options: {
                    conditions: []
                },
            },
            form: {},
            labelPrefix: 'settings.workflow.label.',
            editor: null,
            source: false,
            sourceJson: null,
        }
    },
    mounted() {

    },
    beforeDestroy() {
        bus.$destroy()
    },
    methods: {
        ...mapGetters('Workflow', ['getWorkflow', 'getActions', 'getTodoActions', 'getCustomerActions', 'getBaseActions', 'getConditions', 'getAttributes', 'getCustomerAttributes', 'getTriggers', 'getDateValues', 'getTimeTypes']),
        ...mapGetters('CommonData', ['getEntityStatuses', 'getStatuses', 'getFulfilmentStatuses', 'getEmailTemplates', 'getDocumentTypes', 'getAllWorkflowTriggers']),
        ...mapGetters('Channel', ['getSources']),
        ...mapGetters('Users', ['getUsersList']),
        ...mapGetters('Category', ['getCategoryOptions']),
        shown() {
            this.editor = null
            const commonData = this.$store.dispatch('CommonData/fetchCommonData')
            const workflow = this.$store.dispatch('Workflow/fetchWorkflow', this.payload.id)
            const channels = this.$store.dispatch('Channel/fetchSources')
            const usersList = this.$store.dispatch('Users/fetchUsersList')
            const categories = this.$store.dispatch('Category/fetchOptions')

            Promise.all([commonData, workflow, channels, usersList, categories])
                .then(() => {
                    this.form = {
                        ...JSON.parse(JSON.stringify(this.defaultForm)),
                        ...JSON.parse(JSON.stringify(this.getWorkflow()))
                    }

                    if (Array.isArray(this.form.trigger_options)) {
                        this.form.trigger_options = {}
                    }
                    this.$refs['observer'].reset()

                    const id = document.getElementById("drawflow");
                    this.editor = new Drawflow(id, Vue, this);
                    this.editor.start();

                    bus.$on('getData', (id) => {
                        const dataNode = this.editor.getNodeFromId(id.slice(5))
                        if (dataNode && dataNode.data) {
                            bus.$emit('SendData', {id, dataNode: dataNode.data})
                        }
                    })

                    bus.$on('saveData', (id, setup) => {
                        if (
                            typeof this.editor.drawflow.drawflow['Home'].data != 'undefined'
                            && typeof this.editor.drawflow.drawflow['Home'].data[id.slice(5)] != 'undefined'
                        ) {
                            this.editor.drawflow.drawflow['Home'].data[id.slice(5)].data.setup = setup;
                        }
                    })

                    bus.$on('refreshNode', (id) => {
                        setTimeout(() => {
                            this.editor.updateConnectionNodes(id)
                        }, 100);
                    })

                    this.editor.registerNode('base-action', BaseAction, {}, {});
                    this.editor.registerNode('common-action', CommonAction, {}, {});
                    this.editor.registerNode('start', StartCondition, {}, {});
                    this.editor.registerNode('if', IfCondition, {}, {});
                    this.editor.registerNode('if-customer', IfCustomerCondition, {}, {});
                    this.editor.registerNode('offering-selector', OfferingSelector, {}, {});
                    this.editor.registerNode('customer-selector', CustomerSelector, {}, {});
                    this.editor.registerNode('invoice-selector', InvoiceSelector, {}, {});
                    this.editor.registerNode('todo-selector', TodoSelector, {}, {});
                    this.editor.registerNode('offering-to-customer', OfferingToCustomer, {}, {});
                    this.editor.registerNode('customer-to-offerings', CustomerToOfferings, {}, {});

                    this.editor.zoom_min = 0.1
                    this.editor.zoom_value = 0.1;
                    for (let i = 1; i < 6; i++) {
                        this.editor.zoom_out();
                    }

                    if (this.form.id) {
                        this.editor.import(this.form.setup)
                    } else {
                        this.addCondition('start')
                    }

                    this.somethingChanged()

                    this.editor.on('nodeCreated', () => {
                        this.somethingChanged()
                    })
                    this.editor.on('nodeRemoved', () => {
                        this.somethingChanged()
                    })
                    this.editor.on('nodeDataChanged', () => {
                        this.somethingChanged()
                    })
                    this.editor.on('connectionCreated', () => {
                        this.somethingChanged()
                    })
                    this.editor.on('connectionRemoved', () => {
                        this.somethingChanged()
                    })
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        addCondition(name) {
            if (name == 'start') {
                this.editor.addNode(name, 0, 1, 0, 0, 'start', {setup: {id: name}}, name, 'vue')
            } else if (name == 'if') {
                this.editor.addNode(name, 1, 2, 100, 100, 'condition', {setup: {id: name, conditions: []}}, name, 'vue')
            } else if (name == 'offering-to-customer') {
                this.editor.addNode(name, 1, 1, 100, 100, 'offering-to-customer', {
                    setup: {
                        id: name,
                        conditions: []
                    }
                }, name, 'vue')
            } else if (name == 'customer-to-offerings') {
                this.editor.addNode(name, 1, 1, 100, 100, 'customer-to-offerings', {
                    setup: {
                        id: name,
                        conditions: []
                    }
                }, name, 'vue')
            } else if (name == 'if-customer') {
                this.editor.addNode(name, 1, 2, 100, 100, 'customer-condition', {
                    setup: {
                        id: name,
                        conditions: []
                    }
                }, name, 'vue')
            } else if (name == 'customer-selector') {
                this.editor.addNode(name, 1, 3, 100, 100, 'customer-selector', {
                    setup: {
                        id: name,
                        conditions: []
                    }
                }, name, 'vue')
            } else {
                this.editor.addNode(name, 1, 3, 100, 100, 'offering-selector', {
                    setup: {
                        id: name,
                        conditions: []
                    }
                }, name, 'vue')
            }
        },
        addAction(name) {
            this.editor.addNode(name, 1, 1, 100, 100, 'action', {
                setup: {
                    id: name,
                    name: this.$t('settings.workflow.actions.' + name),
                }
            }, 'common-action', 'vue')
        },
        addBaseAction(name) {
            this.editor.addNode(name, 1, 1, 100, 100, 'base-action', {
                setup: {
                    id: name,
                    name: this.$t('settings.workflow.actions.' + name),
                }
            }, 'base-action', 'vue')
        },
        somethingChanged() {
            this.$nextTick(() => {
                this.test()
            })
        },
        hasParentBlocksWithObject(blocks, connections, object = 'offering') {
            const actionsMap = {
                'offering': ['offering-selector'],
                'customer': ['customer-selector'],
                'todo': ['todo-selector'],
            }
            let connectionFoundCount = 0

            connections.forEach(connection => {
                let connectionFound = false

                Object.keys(blocks).forEach(key => {
                    if (blocks[key].id == connection.node) {
                        if (blocks[key].name == 'start') {
                            if (object == 'offering' && !['cron', 'customer_status_change'].includes(this.form.trigger)) {
                                connectionFound = true
                            }
                            if (object == 'customer' && ['customer_status_change'].includes(this.form.trigger)) {
                                connectionFound = true
                            }
                        } else if (actionsMap[object].includes(blocks[key].name)) {
                            connectionFound = true
                        } else {
                            connectionFound = this.hasParentBlocksWithObject(blocks, blocks[key].inputs?.input_1?.connections ?? [], object)
                        }
                    }
                })
                if (!connectionFound) {
                    return false
                } else {
                    connectionFoundCount++
                }
            })

            if (connections.length && connections.length == connectionFoundCount) {
                return true
            }

            return false;
        },
        test() {
            bus.$emit('ClearError', {})

            const data = this.editor.export()
            const blocks = data.drawflow['Home'].data
            let starts = []

            // TODO: check for loops
            Object.keys(blocks).forEach(key => {
                // if(!blocks[key].data.length) {
                //     this.$set(this.editor.drawflow.drawflow['Home'].data[key],'data',{setup: {id: blocks[key].name}})
                //     bus.$emit('SendData', {id:blocks[key].id, dataNode: this.editor.drawflow.drawflow['Home'].data[key].data})
                // }
                if (!['start', 'offering-selector', 'customer-selector'].includes(blocks[key].html)) {
                    let object = 'offering'

                    // If it is customer action
                    if (this.getCustomerActions().includes(blocks[key].name)) {
                        object = 'customer'
                    }
                    if (this.getTodoActions().includes(blocks[key].name)) {
                        object = 'todo'
                    }
                    // if it is element witch require customer
                    if (['customer-to-offerings', 'if-customer'].includes(blocks[key].name)) {
                        object = 'customer'
                    }

                    if (!this.hasParentBlocksWithObject(blocks, blocks[key].inputs?.input_1?.connections ?? [], object)) {
                        bus.$emit('SendError', {
                            id: blocks[key].id,
                            error: 'This action can\'t detect ' + object + ' to process!'
                        })
                    }
                }

                if (['if', 'if-customer', 'start', 'offering-selector', 'customer-selector'].includes(blocks[key].name)) {
                    let connections = blocks[key].outputs?.output_1?.connections ?? []
                    if (connections.length == 0) {
                        bus.$emit('SendError', {id: blocks[key].id, error: 'This action has no output relation!'})
                    }
                }
                if (blocks[key].name != 'start') {
                    let connections = blocks[key].inputs?.input_1?.connections ?? []
                    if (!connections.length) {
                        bus.$emit('SendError', {id: blocks[key].id, error: 'This action has no input relation!'})
                    }
                } else {
                    starts.push(blocks[key].id)
                }
            })

            if (starts.length > 1) {
                starts.forEach(start => {
                    bus.$emit('SendError', {id: start, error: 'Can be only one start!'})
                })
            }
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    if (this.source) {
                        this.form.setup = JSON.parse(this.sourceJson)
                    } else {
                        this.form.setup = this.editor.export()
                    }
                    this.loading = true;
                    this.$store.dispatch('Workflow/saveWorkflow', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false;
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
        clear() {
            this.$confirm(
                {
                    message: this.$t('common.confirmations.common_confirm'),
                    button: {
                        no: this.$t('common.button.no'),
                        yes: this.$t('common.button.yes')
                    },
                    callback: confirm => {
                        if (confirm) {
                            this.editor.clear()
                        }
                    }
                }
            )
        },
        openSource() {
            this.source = true
            this.sourceJson = this.editor.export()
        },
        entityStatuses(type) {
            const entityStatuses = this.getEntityStatuses()[type] || []
            let list = {}
            entityStatuses.map(val => {
                list[val.id] = val.name
            })

            return list;
        },
        getTriggersOptions() {
            let options = {};

            Object.keys(this.getAllWorkflowTriggers()).forEach(key => {
                this.getAllWorkflowTriggers()[key].forEach(trigger => {
                    options[trigger.id] = key + ': ' + trigger.name
                })
            })

            return options
        }
    },
    computed: {
        sources: function () {
            let sources = {}
            this.getSources().forEach(source => {
                sources[source.id] = source.api_type
            })

            return sources
        },
        triggers: function () {
            let triggers = {}
            this.getTriggers().forEach(trigger => {
                triggers[trigger] = this.$t('settings.workflow.triggers.' + trigger)
            })

            return triggers
        },
    },
    watch: {
        'form.trigger': {
            handler: function (val) {
                this.test()
            },
            deep: true
        },
    }
}
</script>

<style>
#drawflow {
    width: 100%;
    height: 600px;
    text-align: initial;
    background-color: white;
    background-image: linear-gradient(lightgray 1px, transparent .1rem), linear-gradient(90deg, lightgray 1px, transparent .1rem);
    background-size: 2rem 2rem;
}

.drawflow .drawflow-node {
    width: 400px;
    background: lightgray;
}

.drawflow .drawflow-node.selected {
    /*border: solid 2px white;*/
    box-shadow: 10px 5px 5px gray;
}

.drawflow .drawflow-node.start {
    width: 120px;
    background-color: #39f;
    border-radius: 30px;
    text-align: center;
}

.drawflow .drawflow-node.condition {
    background-color: #39f;
    width: 700px;
}

.drawflow .drawflow-node.customer-condition, .drawflow .drawflow-node.offering-to-customer, .drawflow .drawflow-node.customer-to-offerings {
    background-color: #39f;
    width: 700px;
}

.drawflow .drawflow-node.offering-selector {
    background-color: #39f;
    width: 700px;
}

.drawflow .drawflow-node.customer-selector {
    background-color: #39f;
    width: 700px;
}

.drawflow .drawflow-node.base-action {
    background-color: #f9b115;
    width: 700px;
}

.drawflow .drawflow-node.action {
    background-color: #2eb85c;
    width: 700px;
}

.drawflow svg {
    position: inherit;
}

.drawflow .info-header {
    position: absolute;
    top: -18px;
    right: 30px;
}

.drawflow .header-action-type {
    position: absolute;
    top: -18px;
    left: 5px;
}
</style>