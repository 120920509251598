<template>
    <div>
        <condition v-for="(rule,condition_index) in inputVal.conditions"
                   :key="condition_index"
                   :index="condition_index"
                   v-model="inputVal.conditions[condition_index]"
                   :attributes="getConditionAttributes() | optionsVV"
                   :conditions="getConditions()"
                   :date-values="getDateValues()"
                   :time-types="getTimeTypes()"
                   :remove="()=>{remove(condition_index)}"
                   :attributeModel="getAttributeModel()"
        ></condition>

        <b-button variant="success"
                  @click="addCondition()"
                  :title="$t('common.title.add')"
                  class="mr-2"
        >
            <font-awesome-icon icon="plus"/>
            {{ $t('settings.workflow.button.add_condition') }}
        </b-button>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Condition from "@/components/Condition";

export default {
    name: 'Conditions',
    components: {Condition},
    props: {
        'value': [String, Number, Object],
        'detection': [Boolean],
        'customer': [Boolean],
        'invoice': [Boolean],
        'todo': [Boolean],
    },
    data() {
        return {
            loading: true,
            labelPrefix: 'settings.workflow.label.',
        }
    },
    methods: {
        ...mapGetters('Workflow', ['getAttributes', 'getDetectionAttributes', 'getTodoAttributes', 'getInvoiceAttributes', 'getCustomerAttributes', 'getConditions', 'getTimeTypes', 'getDateValues']),
        getAttributeModel() {
            if (this.customer) {
                return 'customer'
            }
            if (this.invoice) {
                return 'invoice'
            }
            if (this.todo) {
                return 'todo'
            }
            return 'offering'
        },
        getConditionAttributes() {
            if (this.customer) {
                return this.getCustomerAttributes();
            }
            if (this.invoice) {
                return this.getInvoiceAttributes();
            }
            if (this.todo) {
                return this.getTodoAttributes();
            }

            if (this.detection) {
                return this.getDetectionAttributes();
            } else {
                return this.getAttributes();
            }
        },
        remove(condition_index) {
            let conditions = this.inputVal.conditions.filter((data, i) => {
                return i != condition_index
            });
            this.$set(this.inputVal, 'conditions', conditions)

        },
        addCondition() {
            let conditions = this.inputVal.conditions
            conditions.push({})
            this.$set(this.inputVal, 'conditions', conditions)
        }
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    },
}
</script>