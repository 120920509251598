<template>
    <zw-sidebar @shown="shown" :title="$t('settings.workflow.selection_title')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-alert variant="info" show>
                    <font-awesome-icon icon="info-circle" class="mr-2"></font-awesome-icon>
                    {{
                        $t('settings.workflow.selection_limited', {
                            entries: response.show_count,
                            total_entries: response.total_count,
                            limit_count: response.limit
                        })
                    }}
                </b-alert>

                <b-table-simple striped v-if="payload.id=='customer-selector'">
                    <b-thead head-variant="info">
                        <b-th>{{ $t('columns.customer.id') }}</b-th>
                        <b-th>{{ $t('columns.customer.company') }}</b-th>
                    </b-thead>
                    <tbody>
                    <b-tr v-for="customer in customers">
                        <b-td><a href="#"
                                 @click="$root.$children[0].openModal('customer-modal', {customerId:customer.id})"
                        >{{ customer.id }}</a></b-td>
                        <b-td><a href="#"
                                 @click="$root.$children[0].openModal('customer-modal', {customerId:customer.id})"
                        >{{ customer.agenturname }}</a></b-td>
                    </b-tr>
                    </tbody>
                </b-table-simple>
                <b-table-simple striped v-else-if="payload.id=='todo-selector'">
                    <b-thead head-variant="info">
                        <b-th>{{ $t('columns.todos.id') }}</b-th>
                        <b-th>{{ $t('columns.todos.title') }}</b-th>
                    </b-thead>
                    <tbody>
                    <b-tr v-for="todo in todos">
                        <b-td><a href="#"
                                 @click="$root.$children[0].openModal('todo-modal', {id:todo.id})"
                        >{{ todo.id }}</a></b-td>
                        <b-td><a href="#"
                                 @click="$root.$children[0].openModal('todo-modal', {id:todo.id})"
                        >{{ todo.title }}</a></b-td>
                    </b-tr>
                    </tbody>
                </b-table-simple>
                <b-table-simple striped v-else>
                    <b-thead head-variant="info">
                        <b-th>{{ $t('columns.offering.id') }}</b-th>
                        <b-th>{{ $t('columns.offering.number') }}</b-th>
                    </b-thead>
                    <tbody>
                    <b-tr v-for="offering in offerings">
                        <b-td>{{ offering.id }}</b-td>
                        <b-td><a href="#"
                                 @click="$root.$children[0].openModal('kva-modal', offering.id)"
                        >{{ offering.number }}</a></b-td>
                    </b-tr>
                    </tbody>
                </b-table-simple>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>

export default {
    name: 'WorkflowSelectionModal',
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            offerings: [],
            customers: [],
            todos: [],
            labelPrefix: 'settings.workflow.label.',
            response: {},
        }
    },
    methods: {
        shown() {
            this.$store.dispatch('Workflow/fetchOfferingSelection', this.payload)
                .then((response) => {
                    if (this.payload.id == 'customer-selector') {
                        this.customers = response.data.items
                    } else if (this.payload.id == 'todo-selector') {
                        this.todos = response.data.items
                    } else {
                        this.offerings = response.data.items
                    }
                    this.response = response.data
                })
                .finally(() => {
                    this.loading = false;
                })
            this.loading = false;
        },
    },
}
</script>